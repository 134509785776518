import '../styles/proyectos.scss';
import ScrollAnimation from 'react-animate-on-scroll';

function Proyectos(){
  return(
    <div className='proyectos-section'>
      <div id='proyectos'></div>
      <div className='proyectos-text'>
      <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
        <h2>Hacemos proyectos realidad</h2>
        <p>Somos especialistas en diseñar, construir y poner en marcha 
        soluciones de ventilación industrial y comercial. Brindamos nuestros 
        servicios en todo el territorio argentino y en el exterior para más de 
        siete sectores industriales, siendo elegidos por las más grandes empresas 
        como: <span>Coca-Cola, AB InBev, FV, Molinos, Toyota, Arcos Dorados, Metrovías, entre otros.</span>
      </p>
      </ScrollAnimation>
      </div>
      <span className='background-img'></span>
      <div className='proyectos-data'>
        <div className='data-container'>
          <div className='data-box'>
            <p>+500</p>
            <span>Proyectos realizados</span>
          </div>
          <div className='data-box'>
            <p>+35</p>
            <span>Años de experiencia</span>
          </div>
          <div className='data-box'>
            <p>+11.000</p>
            <span>Horas hombre de trabajo</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Proyectos;
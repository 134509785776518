import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from '../pages/home';
import ProductList from './productos/productList';
import Electromecanica from './servicios/electromecanica';
import Torres from './servicios/torres';
import Ventilacion from './servicios/ventilacion';

export function Routes(){
  return (
    <Switch>
      <Route path="/" exact component={Home}></Route>
      <Route path="/ventilacion-industrial" component={Ventilacion}></Route>
      <Route path="/instalaciones-electromecanicas" component={Electromecanica}></Route>
      <Route path="/torres-lavadoras-de-gases" component={Torres}></Route>
      <Route path="/productos" component={ProductList}></Route>
      <Redirect to="/" />
    </Switch>
  );
}
import React from 'react';
import '../styles/simpleHeader.scss'
import { HashLink as Link } from 'react-router-hash-link';
import { HamburgerSlider } from 'react-animated-burgers';
import logoGreen from '../assets/logo-blue.png';

class SimpleHeader extends React.Component{
  state = {
    isActive: false,
    headerActive: false
  }

  toggleButton = () => {
    this.setState({
      isActive: !this.state.isActive
    })
  } 
  render(){
  

  return <div className='simple-header'>
      <a href='/' className='brand'>
        <img className={'logo-container logo-green'} src={logoGreen} alt=""/>
        </a>
      <div className='hamburguer'>
      <HamburgerSlider 
          isActive={this.state.isActive} 
          toggleButton={this.toggleButton} 
          buttonColor="#fff" 
          barColor='#373c41' 
        />
      </div>
      <div className={`menu ${this.state.isActive ? 'showMenu' : 'menu'}`}>
        <Link className='link-simple' smooth to='/'>INICIO</Link>
        <Link className='link-simple' smooth to='/home/#nosotros'>NOSOTROS</Link>
        <Link className='link-simple' smooth to='/home/#proyectos'>PROYECTOS</Link>
        <Link className='link-simple' smooth to='/home/#servicios'>SERVICIOS</Link>
        <Link className='link-simple' smooth to='/home/#productos'>PRODUCTOS</Link>
        <Link className='link-simple' smooth to='/home/#contacto'>CONTACTO</Link>
      </div>
    </div>
  }
}

export default SimpleHeader;
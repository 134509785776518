import '../../styles/servicios.scss';
import Banner from '../banner';
import ventilacion from '../../assets/ventilacion.jpg';
import electromecanica from '../../assets/electromecanica.jpg';
import torres from '../../assets/torres.jpg';
import Card from '../card';
import ScrollAnimation from 'react-animate-on-scroll';

function Servicios(){
  return(
    <div className='servicios-section'>
      <div id='servicios'></div>
      <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
        <Banner text='Diseño. Construcción. Montaje.'/>
        <h2>Servicios</h2>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
      <div className='servicios'>
      <Card 
        nombre='Ventilación Industrial' 
        imagen={ventilacion} 
        action='ver mas'
        to='/ventilacion-industrial'
        />
      <Card 
        nombre='Instalaciones Electromecánicas' 
        imagen={electromecanica} 
        action='ver mas'
        to='/instalaciones-electromecanicas'
        />
      <Card 
        nombre='Torres Lavadoras de Gases' 
        imagen={torres} 
        action='ver mas'
        to='/torres-lavadoras-de-gases'
        />
      </div>
      </ScrollAnimation>
    </div>
  );
}

export default Servicios;
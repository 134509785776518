import React from 'react';
import '../styles/card.scss';
import { HashLink as Link } from 'react-router-hash-link';

interface IProducto{
  imagen: string;
  nombre: string;
  action: string;
  to: string;
}

class Card extends React.Component<IProducto>{
  render(){
    return <Link to={this.props.to} smooth className='card'>
    <div className='card-box'>
      <img src={this.props.imagen} alt=""/>
      <p>{this.props.nombre}</p>
    </div>
    <div className='consulta'>{this.props.action}</div>
    </Link>
  }
}

export default Card;
import portada from '../assets/portada4.jpg';
import Button from '../components/button';
import '../styles/home.scss';
import {Animated} from "react-animated-css";
import { HashLink as Link } from 'react-router-hash-link';

function Portada() {
  return(
    <div className='top-section'>
      <img src={portada} alt=""/>
      <div className='top-container'>
      <div className='call-to-action'>
      <Animated animationIn="fadeInLeft" animationOut="fadeOut" isVisible={true}>
        <h1>Líderes en soluciones de ventilación industrial</h1>
        <p>Desarrollamos proyectos de forma integral. Abarcando los procesos de diseño,
          fabricación y montaje.
        </p>
        <Link to={'#contacto'} smooth>
          <Button 
            style='btn-header'  
            text='CONTACTANOS'
          />
        </Link>
        </Animated>
      </div>
      </div>  
    </div> 
  );
}

export default Portada;
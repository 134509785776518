import Banner from "./banner";
import '../styles/contacto.scss';
import Form from "./form";
import ScrollAnimation from 'react-animate-on-scroll';

function Contacto() {
  return(
 <div className='contacto-section'>
   <div id='contacto'></div>
   <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
    <Banner text='Estamos para ayudarte' />
    <h2>Contacto</h2>
   </ScrollAnimation>
   <div className='contacto-info'>
    <div className='phone'>
    <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
      <p><strong>Teléfonos: </strong>3526-3959 | 3526-6467</p>
      <p><strong>Email: </strong> info@aeromovingsa.com.ar</p>
      <p><strong>Fabrica: </strong>Lavalle 1459 - Ciudad de Buenos Aires.</p>
    </ScrollAnimation>
    </div>
    <div className='form'>
    <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
     <Form />
     </ScrollAnimation>
    </div>
   </div>
 </div>
  );
}

export default Contacto;
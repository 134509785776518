import React from 'react'
import '../styles/button.scss';

interface IButton{
  style?: string;
  type?: 'submit' | 'button' | 'reset' | undefined;
  text: string;
  handleClick?: any;
}

class Button extends React.Component <IButton> {
  render(){
  
  const classes = this.props.style || 'btnStyle';

  return <button 
      className={classes} 
      type={this.props.type || 'submit'}
      onClick={() => this.props.handleClick && this.props.handleClick()}
    >
      {this.props.text}
    </button>
  }
}

export default Button;
import '../../styles/productos.scss';
import Banner from '../banner';
import Button from '../button';
import productos from '../../assets/productos.jpg';
import ScrollAnimation from 'react-animate-on-scroll';

function Productos() {
  return(
    <div className='productos-section'>
      <div id='productos'></div>
      <div className='title'>
      <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
        <Banner text='Calidad. Experiencia. Garantía.' style='banner-white'/>
        <h2>Productos</h2>
        <div className='product-img'><img src={productos} alt=""/></div>
      </ScrollAnimation>
      </div>
      <div className='products'>
        <div className='products-content'>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={2}>
            <h3>Fabricados por nosotros</h3>
            <p>En Aeromoving fabricamos una amplia gama de productos para poder brindar una solución integral a nuestros clientes.</p>
          <div className='catalogo'>

          <ul>
            <li><strong>1.</strong> Conductos y accesorios </li>
            <li><strong>2.</strong> Cabinas filtrantes de aire  </li>
            <li><strong>3.</strong> Filtros </li>
            <li><strong>4.</strong> Cortinas de aire </li>
            <li><strong>5.</strong> Torres lavadoras de gases </li>
            <li><strong>6.</strong> Campana de extracción industriales y gastronómicas</li>
          </ul>
          <ul>
            <li><strong>7.</strong> Cabinas acústicas y trampas de sonido </li>
            <li><strong>8.</strong> Sistema de extracción e inyección  </li>
            <li><strong>9.</strong> Ventiladores axiales y centrífugos </li>
          </ul>
          </div>
        </ScrollAnimation>
        </div>
        <a href="/productos">
          <Button text='VER PRODUCTOS'/>
        </a>
      </div>
    </div>
  );
}

export default Productos;
import '../styles/nosotros.scss';
import nosotros from '../assets/nosotros.jpg';
import Banner from './banner';
import ScrollAnimation from 'react-animate-on-scroll';

function Nosotros(){
  return(
    <div className='nosotros-section'>
      <div id='nosotros'></div>
      <div className='nosotros-img'>
      <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
        <div className='img'><img src={nosotros} alt=""/></div>
      </ScrollAnimation>
      </div>
      <div className='nosotros'>
      <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
        <Banner text='Historia. Servicios. Innovación.'/>
        <div className='nosotros-content'>
          <h2>Quiénes Somos</h2>
          <p>Somos una empresa familiar con 35 años de experiencia ofreciendo 
          la más amplia variedad en fabricación de piezas, conductos, equipos 
          para sistemas de ventilación industrial y soluciones en movimiento de aire. 
          Con especial enfoque en ingeniería, proyecto, montaje y diseño, nuestro 
          principal objetivo es brindar soluciones integrales, anticipándonos a las 
          necesidades de un mercado en constante evolución, incorporando a nuestro 
          catalogo nuevos productos y soluciones de ventilación, basadas en la eficiencia, 
          el ahorro energético y cuidado ambiental.<br/>
          </p>
        </div>
      </ScrollAnimation>
      </div>
    </div>
  );
}

export default Nosotros;
import Button from "./button";

function Form(){
  return(
    <form action="contacto.php" method="post">
        <div className='input'>
          <label htmlFor="">Nombre:</label>
          <input type="text" name="nombre" id="nombre"  required/>
        </div>
        <div className='input'>
          <label htmlFor="">Empresa:</label>
          <input type="text" name="empresa" id="empresa"  required/>
        </div>
        <div className='input'>
          <label htmlFor="">Email:</label>
          <input type="email" name="email" id="email"  required/>
        </div>
        <div className='input'>
          <label htmlFor="">Escribe tu consulta aquí:</label>
          <textarea name="f_comentarios" id="f_comentarios" required></textarea>
        </div>
        <div className='btn-position'>
          <Button text='ENVIAR CONSULTA' />
        </div>
    </form>
  );
}

export default Form;
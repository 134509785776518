import React from 'react';
import '../styles/header.scss'
import { HashLink as Link } from 'react-router-hash-link';
import { HamburgerSlider } from 'react-animated-burgers';
import logo from '../assets/logo-white.png';
import logoGreen from '../assets/logo-blue.png';

class Header extends React.Component{
  state = {
    isActive: false,
    headerActive: false
  }

  toggleButton = () => {
    this.setState({
      isActive: !this.state.isActive
    })
  } 

  listenScrollEvent = () => {
    if (window.scrollY > 80) {
      this.setState({headerActive: true})
    } else {
      this.setState({headerActive: false})
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }
  

  render(){

  const classes = this.state.headerActive ? 'link active' : 'link';
  const activeMenu = this.state.headerActive ? 'header active' : 'header';
  //const changeBackground = this.state.isActive ? 'background' : 'menu';

  return <div className={activeMenu}>
      <a href='/' className='brand'>
        <img className={'logo-container logo-white'} src={logo} alt=""/>
        <img className={'logo-container logo-green'} src={logoGreen} alt=""/>
        </a>
      <div className='hamburguer'>
      <HamburgerSlider 
          isActive={this.state.isActive} 
          toggleButton={this.toggleButton} 
          buttonColor="transparent" 
          barColor={this.state.headerActive ? '#373c41' : 'white'} 
        />
      </div>
      <div className={`menu ${this.state.isActive ? 'showMenu' : 'menu'}`}>
        <Link className={classes} smooth to='/'>INICIO</Link>
        <Link className={classes} smooth to='/home/#nosotros'>NOSOTROS</Link>
        <Link className={classes} smooth to='/home/#proyectos'>PROYECTOS</Link>
        <Link className={classes} smooth to='/home/#servicios'>SERVICIOS</Link>
        <Link className={classes} smooth to='/home/#productos'>PRODUCTOS</Link>
        <Link className={classes} smooth to='/home/#contacto'>CONTACTO</Link>
      </div>
    </div>
  }
}

export default Header;
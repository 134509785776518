import Banner from "../banner";
import ventiraldor from '../../assets/ventilador.jpg'
import cortinas from '../../assets/cortina.jpg'
import conductos from '../../assets/conductos.jpg'
import filtros from '../../assets/filtro.jpg'
import extractores from '../../assets/extractores.jpg'
import torres from '../../assets/torres.jpg'
import cabinas from '../../assets/cabina.jpg'
import campana from '../../assets/campana.jpeg'
import sonido from '../../assets/cabina-sonido.jpeg'
import Card from "../card";
import '../../styles/productos.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import SimpleHeader from '../../components/simpleHeader';

function ProductList(){
    return (
      <div>
      <SimpleHeader  />
      <div className='list-container'>
        <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
          <Banner text='Calidad. Experiencia. Garantía.'/>
          <h2>Productos</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={3}>
        <div className='list'>
          <Card nombre='Ventiladores' imagen={ventiraldor} action='realizar consulta' to='home/#contacto'/> 
          <Card nombre='Cortinas de Aire' imagen={cortinas} action='realizar consulta' to='/home/#contacto'/>
          <Card nombre='Conductos y Accesorios' imagen={conductos} action='realizar consulta' to='/home/#contacto'/>
          <Card nombre='Filtros' imagen={filtros} action='realizar consulta' to='/home/#contacto'/>
          <Card nombre='Extractores' imagen={extractores} action='realizar consulta' to='/home/#contacto'/>
          <Card nombre='Torres Lavadoras' imagen={torres} action='realizar consulta' to='/home/#contacto'/>
          <Card nombre='Cabinas de Aire' imagen={cabinas} action='realizar consulta' to='/home/#contacto'/>
          <Card nombre='Campanas de Extracción' imagen={campana} action='realizar consulta' to='/home/#contacto'/>
          <Card nombre='Cabinas de Sonido' imagen={sonido} action='realizar consulta' to='/home/#contacto'/>
        </div>
        </ScrollAnimation>
      </div>
      </div>
    );
 }

export default ProductList;
import '../styles/banner.scss';

function Banner(props: any) {
  const classes = props.style || 'banner'
  
  return(
    <div className={classes}><p>{props.text}</p></div>
  );
}

export default Banner;
import React from 'react';
import Contacto from '../components/contacto';
import Footer from '../components/footer';
import Header from '../components/header';
import Nosotros from '../components/nosotros';
import Portada from '../components/portada';
import Productos from '../components/productos/productos';
import Proyectos from '../components/proyectos';
import Servicios from '../components/servicios/servicios';
import '../styles/home.scss';
import attach from '../assets/attach.svg';

function Home() {
  return (
    <div>
      <Header />
      <Portada />
      <Nosotros />
      <Proyectos />
      <Servicios />
      <Productos />
      <Contacto />
      <div className='work-with-us'>
          <div className='work-with-us-container'>
            <h3>Trabajá con nosotros</h3>
            <p>Sumate a nuestro equipo</p>
            <form className='form-work' action="work.php" method="post">
              <input type="email" name="email" id="email"  placeholder='Email'/>
              <label className="file-upload">
                <img src={attach} alt="" />
                <input type="file" name="file" id="file"/>
                Adjuntar CV
              </label>
              <button type='submit'>Enviar CV</button>
            </form>
            </div>
        </div>
      <Footer />
    </div>
  );
}

export default Home;
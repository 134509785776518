import Banner from "../banner";
import '../../styles/servicios.scss';
import electromecanica from '../../assets/electromecanica.jpg';
import Button from "../button";
import { HashLink as Link } from 'react-router-hash-link';
import SimpleHeader from "../simpleHeader";
import ScrollAnimation from "react-animate-on-scroll";

function Electromecanica(){
  return(
    <div>
      <SimpleHeader />
    <div className='servicio-info'>
    <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
      <Banner text='Diseño. Construcción. Montaje.'/>
      <h2>Instalaciones Electromecánicas</h2>
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={3}>
      <div className='servicios-detalles'>
        <div className='imagen'>
          <img src={electromecanica} alt=""/>
        </div>
        <div className='detalles'>
          <div className='detalles-box'>
            <h3>Nuestro servicio incluye:</h3>
            <ul>
              <li>- Ingeniería, proyecto y montajes de obras electromecánicas.</li>
              <li>- Construcción de tableros de fuerza, comando y señalización.</li>
              <li>- Instalaciones eléctricas antiexplosivas.</li>
            </ul>
          </div>
          <Link smooth to='/home/#contacto' >
            <Button text='CONTACTANOS' />
          </Link>
        </div>
      </div>
    </ScrollAnimation>
    </div>
    </div>
    );
}

export default Electromecanica;